import {Component, OnInit} from '@angular/core';
import {SocketService} from '../socket.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-lobby',
  templateUrl: './lobby.component.html',
  styleUrls: ['./lobby.component.scss']
})
export class LobbyComponent implements OnInit {

  displaySettings: boolean = false;
  boardSizeInput: string;
  language: string;
  constructor(public router: Router, public socketService: SocketService) {
  }

  ngOnInit(): void {
    if (this.checkConnection()) {
      if (this.socketService.curRoom.board == null) {
        this.boardSizeInput = '5';
        this.language = 'he';
      } else {
        this.boardSizeInput = this.socketService.curRoom.board.boardSize.toString();
        this.language = this.socketService.curRoom.board.language;
      }
      this.socketService.requestGetUsers();
      this.socketService.requestGetInGame();
    }
  }

  checkConnection() {
    if (!this.socketService.checkConnection() || this.socketService.curRoom == null) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }

  startGame() {
    this.checkConnection();
    // tslint:disable-next-line:radix
    this.socketService.inGame(parseInt(this.boardSizeInput), this.language, this.socketService.curRoom.mainSpyTimer, this.socketService.curRoom.mainSpyLength, this.socketService.curRoom.spyTimer, this.socketService.curRoom.spyLength);
  }

  // printBoard() {
  //   for (let i = 0; i < this.socketService.curRoom.board.boardSize; i++) {
  //     let words = '';
  //     for (let j = 0; j < this.socketService.curRoom.board.boardSize; j++) {
  //       words += '| ' + this.socketService.curRoom.board.cards[i][j].word + ', ' + this.socketService.curRoom.board.cards[i][j].color + ' |';
  //     }
  //     console.log(words);
  //   }
  // }

}
