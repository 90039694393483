import {Card} from './card';

export class Board {
  boardSize: number;
  blackCards: number;
  neutralCards: number;
  blueCards: number;
  redCards: number;
  cards: Card[][];
  language: string;

  constructor(boardSize, language) {
    this.boardSize = boardSize;
    this.language = language;
    this.blackCards = 0;
    this.neutralCards = 0;
    this.blueCards = 0;
    this.redCards = 0;
    this.cards = [];

    for (let i = 0; i < boardSize; i++){
      this.cards[i] = [];
    }
  }
}
