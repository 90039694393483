<div class="game-background" style="">
  <h1 id="title" style="font-weight: bolder; font-size:70px; width: 100%; text-align: center; margin-top:25px; line-height: normal;">CODE NAMES</h1>
  <img id="logo" [hidden]="join || create" src="assets/code-names-logo.png"
       style="position: absolute;bottom:25px; width: 200px; height: auto; left: 50%; transform: translateX(-50%); user-select: none;"/>
  <button id="home-button" [hidden]="!join && !create" class="btn btn-info" (click)="join = false; create = false;" style="position:  absolute; top: 25px; left: 25px;">Home
  </button>
  <!--<button class="btn btn-info" (click)="socketService.printRooms();" style="margin: 10px;">Print Rooms</button>-->

  <div id="button-container" [hidden]="join || create"
       style="position: absolute; top:40%; transform: translate(-50%, -50%); left: 50%; display: grid; grid-template-columns: auto auto; grid-template-rows: minmax(0, 1fr); grid-column-gap: 100px;">
    <button class="btn x-big-btn btn-primary main-menu-button" type="button" (click)="join = true;" style="">Join A Room!</button>
    <button class="btn x-big-btn btn-primary main-menu-button" type="button" (click)="create = true;" style="">Create A Room!</button>
  </div>
  <button id="rules" class="btn x-big-btn btn-danger main-menu-button" type="button" (click)="showRules = true;" [hidden]="join || create">Rules</button>

  <div id="showRules" *ngIf="showRules"
       style="background-color: rgba(0,0,0,0.7); width: 100%; height: 100%; position: absolute; z-index: 999; top: 0px; left: 0px; ">
    <p id="close-rules"
      style="position: absolute; top: 15px; left: 50%; transform: translateX(-50%); color: white; font-weight: bold; font-size: 16px; width: 80%; text-align: center; padding: 4px; border: 3px solid white; border-radius: 10px; user-select: none;"
      (click)="showRules = false">
      Click here to close
    </p>
    <div id="rules-wrapper"
         style="position: absolute; left: 50%; bottom: 10px; transform: translateX(-50%); width: 90%; height: 90%; background-color: gray; border-radius: 10px; overflow-y: auto; display: grid; justify-content: center; padding: 20px;">
      <mat-form-field appearance="fill">
        <mat-label>Choose a language</mat-label>
        <mat-select [(value)]="rulesLan" style="width: 100%">
          <mat-option value="he">Hebrew</mat-option>
          <mat-option value="en">English</mat-option>
        </mat-select>
      </mat-form-field>
      <ng-container *ngIf="rulesLan == 'he'">
        <p *ngFor="let line of heRules" style="font-size: 18px; color:white; line-height: normal; width: 100%; height: auto; text-align: right; justify-self: right;" dir="rtl">
          {{line}}
        </p>
      </ng-container>
      <ng-container *ngIf="rulesLan == 'en'">
        <p *ngFor="let line of enRules" style="font-size: 18px; color:white; line-height: normal; width: 100%; height: auto; text-align: left; justify-self: left;" dir="ltr">
          {{line}}
        </p>
      </ng-container>
    </div>
  </div>

  <div [hidden]="!join" class="form-div">
    <h1 align="center">Join a game!</h1>
    <form class="form-container" (ngSubmit)="joinSubmit()" #roomForm="ngForm" style="width: 20%; text-align:center; margin:15px auto auto;" autocomplete="off">
      <div class="form-group">
        <label for="username">Enter your name</label>
        <input dir="auto" id="username" class="form-control fit-text-user" #username="ngModel" type="text" name="username"
               [(ngModel)]="form.username" required maxlength="6" (ngModelChange)="fitTexts(form.username, 'user')">
        <div [hidden]="username.valid || username.pristine" class="alert alert-danger">
          Username is required!
        </div>
      </div>
      <div class="form-group">
        <label for="roomName">Enter room name</label>
        <input dir="auto" style="text-transform: uppercase;" id="roomName" #roomName="ngModel" class="form-control fit-text-room" type="text" name="roomName"
               [(ngModel)]="form.roomName" required maxlength="15" (ngModelChange)="fitTexts(form.roomName, 'room')"/>
        <div [hidden]="roomName.valid || roomName.pristine" class="alert alert-danger">
          Room name is required!
        </div>
      </div>
      <div class="form-group">
        <button class="btn big-btn btn-success" type="submit" [disabled]="!roomForm.form.valid">Enter Game!</button>
      </div>
    </form>
  </div>

  <div [hidden]="!create" class="form-div">
    <h1 align="center">Create a new game!</h1>
    <form class="form-container" (ngSubmit)="createSubmit()" #roomForm="ngForm" style="width: 20%; text-align:center; margin:15px auto auto;" autocomplete="off">
      <div class="form-group">
        <label for="username-create">Enter your name</label>
        <input id="username-create" class="form-control fit-text-user" #username="ngModel" type="text" name="username" dir="auto"
               [(ngModel)]="form.username" required maxlength="6" (ngModelChange)="fitTexts(form.username, 'user')">
        <div [hidden]="username.valid || username.pristine" class="alert alert-danger">
          Username is required!
        </div>
      </div>
      <div class="form-group">
        <label for="roomName-create">Enter room name</label>
        <input id="roomName-create" style="text-transform: uppercase;" #roomName="ngModel" class="form-control fit-text-room" type="text" name="roomName" dir="auto"
               [(ngModel)]="form.roomName" required maxlength="15" (ngModelChange)="fitTexts(form.roomName, 'room')"/>
        <div [hidden]="roomName.valid || roomName.pristine" class="alert alert-danger">
          Room name is required!
        </div>
      </div>
      <div class="form-group">
        <button class="btn big-btn btn-success" type="submit" [disabled]="!roomForm.form.valid">Enter Game!</button>
      </div>
    </form>
  </div>
</div>
