export class User {
  name: string;
  team: number;
  isHost: boolean = false;
  master: boolean = false;

  constructor(name, team, master) {
    this.name = name;
    this.team = team;
    this.isHost = false;
    this.master = master;
  }

  reset() {
    this.name = '';
    this.team = -1;
    this.isHost = false;
    this.master = false;
  }
}
