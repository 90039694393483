import {User} from './user';
import {Question} from './question';
import {Board} from './board';

export class Room {
  name: string;
  usersInRoom: User[];
  teams: [string, string];
  board: Board;
  turnName: string;
  turn: number;
  chosenWord: string;
  amount: number;
  colorNames = ['None', 'Blue', 'Red', 'Black', 'Neutral'];
  colorValues = ['white', 'dodgerblue', 'red', '#333333', '#EFC300'];
  amountLeft: number;
  mainSpyTimer: boolean;
  mainSpyLength: number;
  spyTimer: boolean;
  spyLength: number;

  constructor(name: string) {
    this.name = name;
    this.usersInRoom = [];
    this.teams = [this.colorValues[1], this.colorValues[2]];
    this.turnName = this.colorNames[0];
    this.turn = -1;
    this.board = null;
    this.chosenWord = '';
    this.amount = -1;
    this.amountLeft = 0;
    this.mainSpyTimer = false;
    this.mainSpyLength = 90;
    this.spyTimer = false;
    this.spyLength = 90;
  }

  reset() {
    console.log('reseting room');
    this.turnName = this.colorNames[0];
    this.turn = -1;
    this.board = new Board(this.board.boardSize, this.board.language);
    this.chosenWord = '';
    this.amount = -1;
    this.amountLeft = 0;
  }
}
