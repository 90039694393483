export class Card {
  word: string;
  indexes: [];
  color: string;
  showTrueColor: boolean;
  constructor(word, indexes, color, showTrueColor) {
    this.word = word;
    this.indexes = indexes;
    this.color = color;
    this.showTrueColor = showTrueColor;
  }
}
