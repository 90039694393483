/* tslint:disable:prefer-const */
import {Component, HostListener, OnInit} from '@angular/core';
import {SocketService} from '../socket.service';
import {Router} from '@angular/router';
import {User} from '../user';
import {Board} from '../board';
import {Card} from '../card';

declare var $: any;

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss']
})

export class GameComponent implements OnInit {



  constructor(public socketService: SocketService, private  router: Router) {
  }

  inGameMessage;
  gameOver: boolean = false;
  gameStarted: boolean = false;
  showColorsMaster = false;
  choseWord = false;
  word: string = '';
  amount: number = 1;
  displayChooseWord: boolean;
  winner: number;
  displayGameOver: boolean = false;
  clickedOnBlack: boolean;
  tie: boolean;
  mainSpyShow: boolean = false;
  spyShow: boolean = false;
  interval;
  mainSpyTimeLeft: number;
  spyTimeLeft: number;
  isMobile: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event){
    if (event.target.innerWidth <= 480 && this.isMobile === false) {
      this.isMobile = true;
      console.log('changed to mobile window size');
    }
    else if (event.target.innerWidth > 480 && this.isMobile === true){
      this.isMobile = false;
      console.log('changed to desktop size');
    }
  }

  ngOnInit(): void {
    if (!this.socketService.checkConnection()) {
      this.router.navigate(['']);
      return;
    }
    if ($(window).width() <= 480) {
      this.isMobile = true;
      console.log('started with mobile window size');
    }
    this.getInGameMessage();
    this.getTurn();
    this.getGameStarted();
    this.getBoard();
    this.getCard();
    this.getChosenWords();
    this.getTimer();
    this.updateTime();
    this.activateTimer();
    this.socketService.requestGetBoard();
    this.socketService.requestGetGameStarted();
    this.socketService.requestGetTimer();
  }

  leaveRoom() {
    this.socketService.leaveRoom();
    this.router.navigate(['/']);
  }


  getInGameMessage() {
    this.socketService.socket.on('in-game_message', (message) => {
      console.log('got-in-game_message');
      if (message.type === 'game-over') {
        this.winner = message.winner;
        console.log(message.clickedOnBlack);
        this.clickedOnBlack = message.clickedOnBlack;
        this.tie = this.winner === -2;
        this.gameOver = true;
        this.displayGameOver = true;
      } else {
        console.log(message);
      }
    });
  }

  activateTimer() {
    this.socketService.socket.on('activate-timer', (timer) => {

      console.log('got activate timer: ' + timer);
      if (timer === 'main') {
        console.log('main spy length: ' + this.socketService.curRoom.mainSpyLength.toString());
        this.displayChooseWord = false;
        this.mainSpyTimeLeft = this.socketService.curRoom.mainSpyLength;
        this.mainSpyShow = true;
        this.spyShow = false;
      } else if (timer === 'spy') {
        this.spyTimeLeft = this.socketService.curRoom.spyLength;
        this.spyShow = true;
        this.mainSpyShow = false;
      }
    });
  }

  updateTime() {
    this.socketService.socket.on('update-time', (data) => {
      console.log('updating time...');
      this.mainSpyTimeLeft = data.mainSpyTimeLeft;
      this.spyTimeLeft = data.spyTimeLeft;
    });
  }

  getTimer() {
    this.socketService.socket.on('get-timer', (data) => {
      console.log('main spy timer length: ' + data.mainSpyLength);
      this.socketService.curRoom.mainSpyTimer = data.mainSpyTimer;
      this.socketService.curRoom.spyTimer = data.spyTimer;
      this.socketService.curRoom.mainSpyLength = data.mainSpyLength;
      this.socketService.curRoom.spyLength = data.spyLength;
      if (this.mainSpyTimeLeft === undefined) {
        this.mainSpyTimeLeft = this.socketService.curRoom.mainSpyLength;
      }
      if (this.spyTimeLeft === undefined) {
        this.spyTimeLeft = this.socketService.curRoom.spyLength;
      }
    });
  }

  getGameStarted() {
    this.socketService.socket.on('get-game-started', (gameStarted) => {
      console.log('got game started');
      this.gameStarted = gameStarted;
      if (this.gameStarted === true) {
        this.socketService.requestGetTurn();
        this.socketService.requestGetGameOver();
        this.socketService.requestGetChoseWord();
        this.showColorsMaster = true;
      }
    });
  }

  getTurn() {
    this.socketService.socket.on('get-turn', (turn) => {
      console.log('got-turn');
      this.socketService.curRoom.turnName = this.socketService.curRoom.colorNames[turn + 1];
      this.socketService.curRoom.turn = turn;
    });
  }

  getCard() {
    this.socketService.socket.on('get-card', (cardData) => {
      console.log(cardData.showTrueColor);
      this.socketService.curRoom.board.cards[cardData.indexes[0]][cardData.indexes[1]] = new Card(cardData.word, cardData.indexes, cardData.color, cardData.showTrueColor);
      // setTimeout(() => this.fitTexts(), 1);
    });
  }

  getChosenWords() {
    this.socketService.socket.on('get-chose-word', (data) => {
      console.log('emitted chose word');
      this.socketService.curRoom.chosenWord = data.word;
      this.socketService.curRoom.amount = data.amount;
      this.choseWord = data.choseWord;
      this.socketService.curRoom.amountLeft = data.amountLeft;
    });
  }

  getBoard() {
    console.log('getting board');
    this.socketService.socket.on('get-board', (boardData) => {
      console.log('got-board: ' + boardData);
      if (boardData === 'reset') {
        this.socketService.curRoom.board = null;
        console.log('reset board');
      } else {
        if (boardData.type === 'board') {
          if (this.socketService.curRoom.board == null) {
            this.socketService.curRoom.board = new Board(boardData.boardSize, boardData.language);
          } else {
            this.socketService.curRoom.board.boardSize = boardData.boardSize;
            this.socketService.curRoom.board.language = boardData.language;
          }
          this.socketService.curRoom.board.blackCards = boardData.blackCards;
          this.socketService.curRoom.board.neutralCards = boardData.neutralCards;
          this.socketService.curRoom.board.blueCards = boardData.blueCards;
          this.socketService.curRoom.board.redCards = boardData.redCards;
          // for (let i = 0; i < this.socketService.curRoom.board.boardSize; i++) {
          //   for (let j = 0; j < this.socketService.curRoom.board.boardSize; j++) {
          //     this.socketService.curRoom.board.cards[i].push(new Card('', [i, j], 'white', false));
          //   }
          // }
        } else if (boardData.type === 'card') {
          this.socketService.curRoom.board.cards[boardData.indexes[0]][boardData.indexes[1]] = new Card(boardData.word, boardData.indexes,
            boardData.color, boardData.showTrueColor);
        } else if (boardData.type === 'finish') {
          // this.fitTexts();
        }

      }
    });
  }

  getDir() {
    let dir = $('.card-word').first().text().charAt(0).charCodeAt(0) < 200 ? 'ltr' : 'rtl';
    return dir;
  }

  /* fitTexts() {
     console.log($('.card-word').length);
     let dir = this.getDir();
     console.log(dir);
     if (dir === 'rtl') {
       $('.card-word').css('font-family', '"Rubik", sans-serif');
     }

     if (dir === 'ltr') {
       $('.card-word').css('font-family', '"Comic Sans MS", cursive, sans-serif');
     }
     //  $('.card-word').fitText(0.2, {minFontSize: '16px', maxFontSize: '40px'});
     console.log('fit texts');
   }*/

  clickedCard(indexes) {
    console.log('clicked card at: ' + indexes[0] + ', ' + indexes[1]);
    console.log('game started: ' + this.gameStarted);
    if (this.gameStarted && (!((this.mainSpyTimeLeft !== 0 && this.mainSpyShow) || (this.spyTimeLeft !== 0 && this.spyShow)) && this.socketService.curRoom.spyTimer)) {
      return;
    }
    if (this.gameOver) {
      return;
    }
    if (!this.gameStarted) {
      if (!this.socketService.user.isHost) {
        return;
      }
      this.socketService.switchWord(indexes);
    } else if (this.socketService.user.master
      && this.socketService.user.team === this.socketService.curRoom.turn
      && this.choseWord) {

      console.log('revealing-card');
      this.socketService.socket.emit('reveal-card', indexes);
    }
  }

  chooseWord() {
    console.log('chose word: ' + this.word);
    if (this.amount < 1) {
      this.amount = 1;
    }
    this.socketService.socket.emit('choose-word', {word: this.word, amount: this.amount});
    this.word = '';
    this.amount = 1;
  }

  lobby() {
    this.socketService.socket.emit('reset-game');
  }
}
