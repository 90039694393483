import {Injectable} from '@angular/core';
import {User} from './user';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';

import * as io from 'socket.io-client';
import {Router} from '@angular/router';
import {Room} from './room';
import {Question} from './question';
import {Card} from './card';
import {Board} from './board';
import {GameComponent} from './game/game.component';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  socket;
  curRoom: Room = null;
  user: User = new User('', 0, false);
  sortedUsers0: User[] = [];
  sortedUsers1: User[] = [];
  sortedUsers: User[] = [];

  constructor(private router: Router) {
  }

  // Connection functions

  connect() {
    this.socket = io(environment.SOCKET_ENDPOINT);
    this.getUsers();
    this.getInGame();
    this.getResetRoom();

  }

  checkConnection() {
    if (this.socket === undefined) {
      return false;
    }
    return true;
  }

  // room functions

  joinRoom(room: string, username: string) {
    this.resetRoom();
    this.socket.emit('join-room', {room, username});
    this.socket.once('join-room_message', (message) => {
      if (message === 'joined') {
        this.setRoom(room, username);
      } else {
        console.log(message);
        alert(message);
      }
    });
  }

  createRoom(roomName: string, username: string) {
    console.log('in function');
    this.resetRoom();
    this.socket.emit('create-room', {roomName, username});
    this.socket.once('create-room_message', (message) => {
      console.log('got-response');
      if (message === 'created') {
        this.setRoom(roomName, username);
      } else {
        alert(message);
      }
    });
  }

  setRoom(roomName: string, username: string) {
    this.curRoom = new Room(roomName.toUpperCase());
    this.user.name = username;
    this.router.navigate(['/lobby']);
  }

  // in game functions

  resetRoom() {
    this.user.reset();
    this.curRoom = null;
  }

  // sync variables

  getInGame() {
    this.socket.on('in-game', (inGame: boolean) => {
      if (inGame) {
        this.router.navigate(['/game']);
      } else {
        this.router.navigate(['/lobby']);
      }
    });
  }

  getUsers() {
    console.log('getting users');
    this.socket.on('get-users', (userData) => {
      console.log('got-users: ' + userData);
      if (userData === 'reset') {
        this.curRoom.usersInRoom = [];
      } else {
        console.log('Team data: ' + userData.team.toString());

        this.curRoom.usersInRoom.push(new User(userData.name, userData.team, userData.master));
        if (userData.name === this.user.name) {
          const host = this.user.isHost;
          this.user = new User(userData.name, userData.team, userData.master);
          this.user.isHost = host;
        }
        this.sortUsers();
      }

      if (this.curRoom.usersInRoom.length > 0 && this.curRoom.usersInRoom[0].name === this.user.name) {
        this.user.isHost = true;
      } else {
        this.user.isHost = false;
      }
    });
  }

  sortUsers() {
    this.sortedUsers = this.curRoom.usersInRoom;
    const team0 = [];
    const team1 = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.sortedUsers.length; i++) {
      if (this.sortedUsers[i].team === 0) {
        team0.push(this.sortedUsers[i]);
      } else if (this.sortedUsers[i].team === 1) {
        team1.push(this.sortedUsers[i]);
      }
    }
    this.sortedUsers0 = team0;
    this.sortedUsers1 = team1;
  }


  leaveRoom() {
    this.socket.emit('leave-room');
    this.resetRoom();
  }

  // lobby functions:
  inGame(boardSize: number, language: string, mainSpyTimer: boolean, mainSpyLength: number, spyTimer: boolean, spyLength: number) {
    this.socket.emit('in-game', {boardSize, language, mainSpyTimer, mainSpyLength, spyTimer, spyLength});
  }

  // end of game functions

  requestGetUsers() {
    console.log('request get users');
    this.socket.emit('get-users');
  }

  requestGetInGame() {
    this.socket.emit('get-in-game');
  }

  requestGetBoard() {
    this.socket.emit('get-board');
  }

  requestGetTurn() {
    this.socket.emit('get-turn');
  }

  requestGetGameOver() {
    this.socket.emit('get-game-over');
  }

  requestGetGameStarted() {
    this.socket.emit('get-game-started');
  }

  requestGetChoseWord() {
    console.log('requesting get chose word');
    this.socket.emit('get-chose-word');
  }

  requestGetTimer(){
    this.socket.emit('get-timer');
  }

  // debug functions
  printRooms() {
    this.socket.emit('print-rooms');
  }

  //
  // alert = () => {
  //   return Observable.create((observer) => {
  //     this.socket.on('alert', (message) => {
  //       observer.next(message);
  //     });
  //   });
  // };
  //

  unbind(eventName: string) {
    this.socket.off(eventName);
  }


  submitAnswer(index: number, timeLeft: number) {
    this.socket.emit('submit-answer', {index, timeLeft});
  }

  startRound() {
    this.socket.emit('start-round', true);
  }

  changeIsMaster(name, master) {
    console.log('change is master');
    this.socket.emit('change-is-master', {name, master});
  }

  switchTeam(user: User) {
    console.log('switched teams');
    this.socket.emit('switch-team', user.name);
    return;
  }

  switchWord(indexes: []) {
    this.socket.emit('switch-word', indexes);
  }

  getResetRoom() {
    this.socket.on('reset-room', (message) => {
      this.curRoom.reset();
      this.router.navigate(['/lobby']);
    });
  }



}
