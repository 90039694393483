import {OnDestroy, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SocketService} from '../socket.service';
import {RoomForm} from '../form-templates';
import {HttpClient} from '@angular/common/http';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  form = new RoomForm('', '');

  dispose = [];

  create: boolean = false;
  join: boolean = false;
  showRules: boolean = false;
  rulesLan: string = 'en';
  enRules: string[] = [];
  heRules: string[] = [];

  constructor(private router: Router, public socketService: SocketService, private http: HttpClient) {
  }

  ngOnInit(): void {
    if (!this.socketService.checkConnection()) {
      this.socketService.connect();
    }
    this.http.get('assets/heRules.txt', {responseType: 'text'}).subscribe(data => {
      this.heRules = data.split(/\r?\n/);
    });
    this.http.get('assets/enRules.txt', {responseType: 'text'}).subscribe(data => {
      this.enRules = data.split(/\r?\n/);
    });
    // this.dispose.push(this.socketService.alert().subscribe((message: string) => {
    //   alert(message);
    // }));
  }

  ngOnDestroy() {
    console.log('destroyed');
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.dispose.length; i++) {
      this.dispose[i].unsubscribe();
    }
  }


  // tslint:disable-next-line:typedef
  joinSubmit() {
    this.socketService.joinRoom(this.form.roomName, this.form.username);
  }

  createSubmit() {
    this.socketService.createRoom(this.form.roomName, this.form.username);
  }

  fitTexts(text, kind) {
    console.log(text);
    const dir = text.charAt(0).charCodeAt(0) < 200 ? 'ltr' : 'rtl';
    if (dir === 'rtl') {
      console.log('he');
      $('.fit-text-' + kind).css('font-family', '"Rubik", sans-serif');
    }

    if (dir === 'ltr') {
      console.log('english');
      $('.fit-text-' + kind).css('font-family', '"Comic Sans MS", cursive, sans-serif');
    }
  }

}
