<div id="game-container">
  <div class="game-background" style="">
    <p id="room-name" style="user-select: none; position: absolute; top: 20px; left: 20px; margin:0; font-size: 20px; font-weight: bold;">Room: {{socketService.curRoom.name}}</p>
    <p class="timer" style="user-select: none; position: absolute; top: 60px; left: 20px; margin:0; font-size: 20px; font-weight: bold;"
       *ngIf="mainSpyShow && mainSpyTimeLeft != 0">{{mainSpyTimeLeft}}</p>
    <p class="timer" style="user-select: none; position: absolute; top: 60px; left: 20px; margin:0; font-size: 20px; font-weight: bold;" *ngIf="spyShow && spyTimeLeft != 0">{{spyTimeLeft}}</p>
    <!--    <div  style="user-select: none; position: absolute; left: -26px; z-index: 100; top:-15px;">-->
    <!--      <div *ngIf="socketService.curRoom != null && !gameOver"-->
    <!--           style="width:120px; height: 120px; transform: rotate(90deg); display: inline-block;">-->
    <!--        <img id="bulb-off" src="assets/light_bulb_off.png" style=" margin:0; height: 100%; width:auto;"-->
    <!--             *ngIf="!gameStarted">-->
    <!--        <img id="bulb-on" src="assets/light_bulb_on_-1.png" style=" margin:0; height: 100%; width:auto;"-->
    <!--             *ngIf="gameStarted && socketService.curRoom.turn == -1">-->
    <!--        <img id="bulb-on-0" src="assets/light_bulb_on_0.png" style=" margin:0; height: 100%; width:auto;"-->
    <!--             *ngIf="gameStarted && socketService.curRoom.turn == 0">-->
    <!--        <img id="bulb-on-1" src="assets/light_bulb_on_1.png" style=" margin:0; height: 100%; width:auto;"-->
    <!--             *ngIf="gameStarted && socketService.curRoom.turn == 1">-->
    <!--      </div>-->
    <!--      <h2 [hidden]="gameOver" style="margin: 0 0 0 -15px;user-select: none; display: inline-block;" class="">-->
    <!--        Turn: {{socketService.curRoom.turnName}}</h2>-->
    <!--    </div>-->

    <div class="scoreboard-menu" style="" *ngIf="socketService.curRoom != null">

      <div [hidden]="!gameOver" style="justify-self: left; display: flex; margin:0; user-select: none;" class=""></div>
      <button class="btn btn-primary scoreboard-menu-items" (click)="displayChooseWord = true"
              *ngIf="!gameOver && this.gameStarted && this.socketService.curRoom.turn == this.socketService.user.team && this.socketService.user.master && !choseWord && ((mainSpyTimeLeft != 0 && mainSpyShow) || !socketService.curRoom.mainSpyTimer)">
        Choose Word
      </button>
      <button class="btn btn-success scoreboard-menu-items" (click)="socketService.startRound()"
              *ngIf="(socketService.user.isHost && !gameStarted && !gameOver)">Start Game
      </button>
      <button class="btn btn-info scoreboard-menu-items" (click)="socketService.startRound()"
              *ngIf="(!gameOver && socketService.user.master && gameStarted && socketService.curRoom.turn == socketService.user.team) && (((mainSpyTimeLeft != 0 && mainSpyShow) || !socketService.curRoom.mainSpyTimer) || ((spyTimeLeft != 0 && spyShow) || !socketService.curRoom.spyTimer))">
        Finish Turn
      </button>
      <button *ngIf="socketService.user.isHost" class="btn btn-info scoreboard-menu-items" (click)="lobby()">Go to lobby</button>
      <button class="btn btn-danger scoreboard-menu-items" (click)="leaveRoom()">Leave Room</button>
    </div>
    <div *ngIf="isMobile else desktopScoreBoard">
      <div class="scoreboard-wrapper">
        <!--      <div class="scoreboard-background" style="">-->
        <!--      </div>-->
        <div class="scoreboard-data">
          <ng-template #teamDisplay let-users='users' let-team='team'>
            <div class="team-wrapper" style="overflow-x: scroll; padding:10px 0px;" [ngClass]="{'rotate': team == 0}">
              <div class="scoreboard-user" [ngClass]="{'rotate': team == 0}" *ngFor="let user of users;" style="background-color:{{socketService.curRoom.colorValues[user.team + 1]}}">
                <div class="scoreboard-user-data" style="height: 100%; width: auto;">
                  <img src="assets/human-icon.png"
                       class=""
                       style="background-color:white; border-radius: 50%; width: 35px; user-select: none;"/>
                </div>
                <p class="scoreboard-user-data" style="justify-content:left; user-select: none;">{{ user.name }}</p>
                <button class="btn btn-success scoreboard-user-data" *ngIf="socketService.user.isHost" (click)="socketService.switchTeam(user)"
                        style="padding:0px 5px; height: 27px; align-self: center;">
                  <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-repeat" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                    <path fill-rule="evenodd"
                          d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
                  </svg>
                </button>
                <mat-checkbox (change)="socketService.changeIsMaster(user.name, $event.checked);" [(ngModel)]="user.master" color="accent" class="scoreboard-user-data"
                              [disabled]="!socketService.user.isHost"></mat-checkbox>
              </div>
            </div>
          </ng-template>

          <ng-template *ngTemplateOutlet="teamDisplay; context:{users: socketService.sortedUsers0, team: 0}"></ng-template>
          <hr style="border: 2px solid black; border-radius: 2px; margin: 5px 10px 5px;">
          <ng-template *ngTemplateOutlet="teamDisplay; context:{users: socketService.sortedUsers1, team: 1}"></ng-template>
        </div>
      </div>
    </div>
    <ng-template #desktopScoreBoard>
      <div class="scoreboard-wrapper">
        <!--      <div class="scoreboard-background" style="">-->
        <!--      </div>-->
        <div class="scoreboard-data">
          <ng-template #teamDisplay let-users='users'>
            <div class="scoreboard-user" *ngFor="let user of users;" style="background-color:{{socketService.curRoom.colorValues[user.team + 1]}}">
              <div class="scoreboard-user-data" style="height: 100%; width: auto;">
                <img src="assets/human-icon.png"
                     class=""
                     style="background-color:white; border-radius: 50%; width: 35px; user-select: none;"/>
              </div>
              <p class="scoreboard-user-data" style="justify-content:left; user-select: none;">{{ user.name }}</p>
              <button class="btn btn-success scoreboard-user-data" *ngIf="socketService.user.isHost" (click)="socketService.switchTeam(user)"
                      style="padding:0px 5px; height: 27px; align-self: center;">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-repeat" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                  <path fill-rule="evenodd"
                        d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
                </svg>
              </button>
              <mat-checkbox (change)="socketService.changeIsMaster(user.name, $event.checked);" [(ngModel)]="user.master" color="accent" class="scoreboard-user-data"
                            [disabled]="!socketService.user.isHost"></mat-checkbox>
            </div>
          </ng-template>

          <ng-template *ngTemplateOutlet="teamDisplay; context:{users: socketService.sortedUsers0}"></ng-template>
          <hr style="border: 2px solid black; border-radius: 2px; margin: 0px 10px 20px;">
          <ng-template *ngTemplateOutlet="teamDisplay; context:{users: socketService.sortedUsers1}"></ng-template>
        </div>
      </div>
    </ng-template>
    <div *ngIf="socketService.curRoom != null && socketService.curRoom.board != null" style="height: auto;">
      <div class="chosen-word" *ngIf="!gameOver && gameStarted && choseWord">
        <div *ngIf="this.socketService.curRoom.board.language == 'en';">
          <ng-container *ngIf="isMobile else desktopEn">
            <p class="chosen-word-en chosen-word-p" dir="ltr" style="text-align: left">{{socketService.curRoom.chosenWord}}</p>
          </ng-container>
          <ng-template #desktopEn>
            <p class="chosen-word-en chosen-word-p" dir="ltr" style="text-align: left">{{socketService.curRoom.chosenWord}}&nbsp;·&nbsp;</p>
          </ng-template>
          <p class="chosen-word-en chosen-word-p" dir="ltr" style="text-align: left">{{socketService.curRoom.amount}}</p>
          <p class="chosen-word-he chosen-word-p" dir="ltr" style="text-align: left">&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</p>
          <p class="chosen-word-en chosen-word-p" dir="ltr" style="text-align: left">{{socketService.curRoom.amountLeft}} </p>
          <p *ngIf="!isMobile" class="chosen-word-en chosen-word-p chosen-word-word" dir="ltr" style="text-align: left">from last turns</p>
        </div>
        <div *ngIf="this.socketService.curRoom.board.language == 'he';">
          <p *ngIf="!isMobile" class="chosen-word-he chosen-word-p" dir="rtl" style="text-align: right">מתורות קודמים</p>
          <p class="chosen-word-he chosen-word-p" dir="ltr" style="text-align: right"> {{socketService.curRoom.amountLeft}}</p>
          <p class="chosen-word-he chosen-word-p" dir="rtl" style="text-align: right">&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</p>
          <p class="chosen-word-he chosen-word-p" dir="ltr" style="text-align: right">{{socketService.curRoom.amount}}</p>
          <ng-container *ngIf="isMobile else desktopWordHe">
            <p class="chosen-word-he chosen-word-p chosen-word-word" dir="rtl" style="text-align: right">{{socketService.curRoom.chosenWord}}</p>
          </ng-container>
          <ng-template #desktopWordHe>
            <p class="chosen-word-he chosen-word-p chosen-word-word" dir="rtl" style="text-align: right">{{socketService.curRoom.chosenWord}}&nbsp;·&nbsp;</p>
          </ng-template>
        </div>
      </div>
      <div *ngIf="isMobile else desktop">
        <div class="card-grid" style="grid-template-columns: minmax(0, 1fr); grid-template-rows: repeat({{socketService.curRoom.board.boardSize}}, minmax(0,1fr));">
          <div class="card-row" *ngFor="let row of socketService.curRoom.board.cards"
               style="grid-template-columns: repeat({{socketService.curRoom.board.boardSize}}, minmax(54px,1fr)); grid-template-rows: 100%;">
            <div *ngFor="let card of row;" class="card-wrapper">
              <ng-container *ngIf="card.showTrueColor || (socketService.user.master && showColorsMaster); else noColor">
                <div class="card" style="background-color: {{socketService.curRoom.colorValues[card.color]}}"
                     [ngClass]="{'show-true-color-border-green': socketService.user.master && card.showTrueColor, 'card-click': socketService.user.master && socketService.user.team == socketService.curRoom.turn && !card.showTrueColor, 'card-border': !socketService.user.master || !card.showTrueColor}"
                     (click)="clickedCard(card.indexes)">
                  <p class="card-word" dir="auto"
                     [ngClass]="{'line-break': card.word.length > 13, 'rtl-text': this.getDir() == 'rtl', 'ltr-text': this.getDir() == 'ltr'}">{{card.word}}</p>
                </div>
              </ng-container>
              <ng-template #noColor>
                <div class="card card-border" style="background-color: white" (click)="clickedCard(card.indexes)">
                  <p class="card-word" dir="auto" style="color:black" [ngClass]="{'rtl-text': this.getDir() == 'rtl', 'ltr-text': this.getDir() == 'ltr'}">{{card.word}}</p>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <ng-template #desktop>
        <div class="card-grid" style="grid-template-columns: minmax(0, 1fr); grid-template-rows: repeat({{socketService.curRoom.board.boardSize}}, minmax(0,1fr));">
          <div class="card-row" *ngFor="let row of socketService.curRoom.board.cards"
               style="grid-template-columns: repeat({{socketService.curRoom.board.boardSize}}, minmax(160px,220px)); grid-template-rows: 100%;">
            <div *ngFor="let card of row;" class="card-wrapper">
              <ng-container *ngIf="card.showTrueColor || (socketService.user.master && showColorsMaster); else noColor">
                <div class="card" style="background-color: {{socketService.curRoom.colorValues[card.color]}}"
                     [ngClass]="{'show-true-color-border-green': socketService.user.master && card.showTrueColor, 'card-click': socketService.user.master && socketService.user.team == socketService.curRoom.turn && !card.showTrueColor, 'card-border': !socketService.user.master || !card.showTrueColor}"
                     (click)="clickedCard(card.indexes)">
                  <p class="card-word" dir="auto" [ngClass]="{'rtl-text': this.getDir() == 'rtl', 'ltr-text': this.getDir() == 'ltr'}">{{card.word}}</p>
                </div>
              </ng-container>
              <ng-template #noColor>
                <div class="card card-border" style="background-color: white" (click)="clickedCard(card.indexes)">
                  <p class="card-word" dir="auto" style="color:black" [ngClass]="{'rtl-text': this.getDir() == 'rtl', 'ltr-text': this.getDir() == 'ltr'}">{{card.word}}</p>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="show-true-color-wrapper" *ngIf="!gameOver && socketService.user.master && !isMobile">
      <mat-checkbox [(ngModel)]="showColorsMaster" color="primary" class="show-true-color" [disabled]="!gameStarted"></mat-checkbox>
      <p style="user-select: none">Show card colors</p>
    </div>
  </div>
  <div class="cards-left-div" style="user-select: none; position: absolute; left: 50%; bottom: 0px; transform: translateX(-50%); margin-left: 125px; height: 18%; z-index: 100;">
    <p class="cards-left-text lamp-p-left" *ngIf="gameStarted && socketService.curRoom.board != null">{{socketService.curRoom.board.blueCards}}</p>
    <div *ngIf="socketService.curRoom != null" class="lamp-div" style="position: absolute; left:0px;">
      <img src="assets/light_bulb_off.png" style=" margin:0; height: 100%; width:auto;"
           *ngIf="(!gameStarted || socketService.curRoom.turn != 0) && !gameOver">
      <img src="assets/light_bulb_on_0.png" style=" margin:0; height: 100%; width:auto;"
           *ngIf="(gameStarted && socketService.curRoom.turn == 0) || gameOver">
    </div>
    <h2 [hidden]="gameOver" class="turn-text">
      Turn: {{socketService.curRoom.turnName}}</h2>
    <p class="cards-left-text lamp-p-right" *ngIf="gameStarted && socketService.curRoom.board != null">{{socketService.curRoom.board.redCards}}</p>
    <div *ngIf="socketService.curRoom != null" class="lamp-div" style="position: absolute; right: 0;">
      <img src="assets/light_bulb_off.png" style=" margin:0; height: 100%; width:auto;"
           *ngIf="(!gameStarted || socketService.curRoom.turn != 1) && !gameOver">
      <img src="assets/light_bulb_on_1.png" style=" margin:0; height: 100%; width:auto;"
           *ngIf="(gameStarted && socketService.curRoom.turn == 1) || gameOver">
    </div>
  </div>


  <div [hidden]="!displayChooseWord || choseWord || !socketService.user.master || socketService.curRoom.turn != socketService.user.team"
       style="background-color:rgba(0,0,0,0.5); height: 100vh; width: 100%; position: absolute; top:0; bottom:0; left: 0; right: 0; margin:auto; z-index: 200;">
    <div id="choose-word-background">
      <button (click)="displayChooseWord=false" class="close" aria-label="Close"
              style="margin-left: auto; width: 30px; height: 30px;">
        <span aria-hidden="true" style="user-select: none;">&times;</span>
      </button>
      <div id="choose-word">
        <div id="choose-word-wrapper">
          <div
            style="margin: auto; width: fit-content; height: auto; position: absolute; top:40%; transform: translate(-50%, -50%); left:50%; display: grid; align-items: center; align-content: center;">
            <mat-form-field appearance="fill">
              <mat-label class="big-label">Enter your word</mat-label>
              <input class="big-input" matInput [(ngModel)]="word" autocomplete="off">
            </mat-form-field>
            <br>
            <mat-form-field appearance="fill">
              <mat-label class="big-label">Enter amount</mat-label>
              <input class="big-input" matInput type="number" [(ngModel)]="amount" min="1" autocomplete="off">
            </mat-form-field>
            <mat-divider></mat-divider>
            <button class="big-button" mat-raised-button color="primary" (click)="chooseWord(); displayChooseWord=false;" style="display: block; margin: 10px auto auto;">Choose!
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div [hidden]="!gameOver || !displayGameOver" class="pop-in-anim"
       style="user-select: none; background-color:rgba(0,0,0,0.8); height: 100vh; width: 100%; position: absolute; top:0; bottom:0; left: 0; right: 0; margin:auto; z-index: 200;">
    <p *ngIf="clickedOnBlack" class="game-over-black-text" style="color: white;">
      You chose the black card!
      <br>
    </p>
    <p *ngIf="!tie" class="game-over-text"
       style="border-color: {{this.socketService.curRoom.colorValues[winner + 1]}}; color: {{this.socketService.curRoom.colorValues[winner + 1]}};">
      Game over! Winner is: {{socketService.curRoom.colorNames[winner + 1]}}</p>
    <p *ngIf="tie" class="game-over-text" style="border-color: white; color: white;">
      Game over! It's a tie</p>
    <button mat-raised-button color="primary" (click)="displayGameOver=false;" style="position: absolute; bottom: 100px; left: 50%; transform: translateX(-50%) scale(2);">Close
    </button>
  </div>
</div>

<!--[ngClass]="{ 'show-true-color-border-white': socketService.user.master && !card.showTrueColor,-->


