<div class="lobby-background" style="">
  <div class="lobby-menu" style="">

    <button class="btn btn-success lobby-menu-items" style="" (click)="startGame()" [hidden]="!socketService.user.isHost">Start Game!
    </button>
    <!--    <button class="btn btn-success lobby-menu-items" style="" (click)="socketService.requestGetBoard()" [hidden]="!socketService.user.isHost">Get Board!-->
    <!--    </button>-->
    <!--    <button class="btn btn-success lobby-menu-items" style="" (click)="printBoard()" [hidden]="!socketService.user.isHost">print Board!-->
    <!--    </button>-->
    <button class="btn btn-primary lobby-menu-items" style="" (click)="displaySettings = !displaySettings" [hidden]="!socketService.user.isHost">Settings
    </button>
    <button class="btn btn-danger lobby-menu-items" (click)="socketService.leaveRoom(); router.navigate(['/']);">Leave Room
    </button>

    <!--    <button class="btn btn-info" (click)="socketService.printRooms();" style="margin: 10px;">Print Rooms</button>-->
  </div>
  <div class="lobby-users" style="">
    <div *ngIf="socketService.curRoom != null && socketService.curRoom.usersInRoom.length > 0">
      <ng-template #displayUser let-users='users'>
        <div class="lobby-user" *ngFor="let user of users" style="background-color:{{socketService.curRoom.colorValues[user.team + 1]}}">
          <div class="lobby-user-data" style="height: 100%; width: auto; user-select: none;">
            <img src="assets/human-icon.png"
                 class=""
                 style="background-color:white; border-radius: 50%; width: 42px; "/>
          </div>
          <p class="lobby-user-data" style="justify-content:left; user-select: none;">{{ user.name }}</p>
          <button class="btn btn-success" *ngIf="socketService.user.isHost" (click)="socketService.switchTeam(user)"
                  style="height: 30px; align-items: center; align-self: center; display: flex;">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-repeat" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
              <path fill-rule="evenodd"
                    d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
            </svg>
          </button>
          <mat-checkbox (change)="socketService.changeIsMaster(user.name, $event.checked)" [(ngModel)]="user.master" color="accent" class="lobby-user-data"
                        [disabled]="!socketService.user.isHost"></mat-checkbox>
        </div>
      </ng-template>
      <ng-template *ngTemplateOutlet="displayUser; context:{users: socketService.sortedUsers0}"></ng-template>
      <hr style="border: 2px solid black; border-radius: 2px;">
      <ng-template *ngTemplateOutlet="displayUser; context:{users: socketService.sortedUsers1}"></ng-template>
    </div>
  </div>
  <div class="room-info">
    <!--    <h2>Room:</h2>-->
    <!--    <h2>Host:</h2>-->
    <h2 *ngIf="socketService.curRoom != null" style="user-select: none; font-size:30px; font-weight: bold; margin-bottom: 25px;">Room: {{socketService.curRoom.name}}</h2>
    <h2 *ngIf="socketService.curRoom.usersInRoom.length > 0" style="user-select: none; font-size:30px; font-weight: bold;">Host: {{socketService.curRoom.usersInRoom[0].name}}</h2>
  </div>
  <div [hidden]="!displaySettings"
       style="background-color:rgba(0,0,0,0.5); height: 100vh; width: 100%; position: absolute; top:0; bottom:0; left: 0; right: 0; margin:auto;">
    <div id="settings-background">
      <button (click)="displaySettings=false" class="close" aria-label="Close"
              style="margin-left: auto; width: 30px; height: 30px;">
        <span aria-hidden="true">&times;</span>
      </button>
      <div id="settings">
        <div id="settings-wrapper">
          <div
            style="margin: auto; width: fit-content; height: auto; position: absolute; top:45%; transform: translate(-50%, -50%); left:50%; display: grid; grid-template-rows: repeat(auto-fit, minmax(0,1fr)); grid-template-columns: auto; align-items: center; align-content: center;">
            <mat-form-field appearance="fill">
              <mat-label class="big-label">Select a board size</mat-label>
              <mat-select name="boardSize" [(value)]="boardSizeInput" class="big-select">
                <mat-option value="4">4</mat-option>
                <mat-option value="5">5</mat-option>
              </mat-select>
            </mat-form-field>
            <br>
            <mat-form-field appearance="fill">
              <mat-label class="big-label">Select a category</mat-label>
              <mat-select name="language" [(value)]="language" class="big-select">
                <mat-option value="he">Hebrew</mat-option>
                <mat-option value="en">English</mat-option>
              </mat-select>
            </mat-form-field>
            <div>
              <mat-checkbox color="primary" [(ngModel)]="socketService.curRoom.mainSpyTimer" style="margin-right: 10px;"></mat-checkbox>

              <mat-form-field style="width: 80%; display: inline-block;">
                <mat-label class="big-label">Main spy timer (sec)</mat-label>
                <input class="big-input" matInput type="number" [(ngModel)]="socketService.curRoom.mainSpyLength" min="1" autocomplete="off" [disabled]="!socketService.curRoom.mainSpyTimer" style="display: inline;">
              </mat-form-field>
            </div>
            <div>
              <mat-checkbox color="primary" [(ngModel)]="socketService.curRoom.spyTimer" style="margin-right: 10px;"></mat-checkbox>

              <mat-form-field style="width: 80%; display: inline-block;">
                <mat-label class="big-label">Normal spy timer (sec)</mat-label>
                <input class="big-input" matInput type="number" [(ngModel)]="socketService.curRoom.spyLength" min="1" autocomplete="off" [disabled]="!socketService.curRoom.spyTimer" style="display: inline;">
              </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <button class="big-button" mat-raised-button color="primary" (click)="displaySettings=false;" style="display: block; margin: 25px auto auto;">OK</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
